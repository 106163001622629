module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uprise.co","short_name":"Uprise","description":"Uprise is a proactive and science-based Employee Assistance Program (EAP) provider in Australia.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#7d60ff","display":"standalone","icon":"src/images/logo.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da663ce2479e45c6000fa97a20f156e2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://uprise.co"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
